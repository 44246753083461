<template>
    <div class="min-h-full relative max-h-full flex flex-col text-base-content overflow-y ">
        <sub-header :backButton="true" class="mb-4" />
        <div class="justify-between flex-grow max-h-full">
            <div class="flex h-full gap-4 flex-1 ">
                <div class="w-2/12 rounded-lg self-start bg-white shadow flex flex-col gap-x-4">
                    <div @click="selectedTab(nav)"
                        v-for="(nav, idx) in navigations"
                        :key="idx"
                        class="px-4 py-3 transition-all relative duration-200 items-center text-sm font-medium flex gap-3  cursor-pointer"
                        :class="{
                            ' bg-primary-300 hover:bg-primary-200 text-base-content-800': currentTab === nav.nav,
                            'hover:bg-gray-100   hover:text-base-content-600': currentTab !== nav.nav,
                            'border-t border-gray-400 border-0' : idx != 0
                        }"
                    >
                        <font-awesome-icon :icon="[nav.iconPrefix || 'fa', nav.icon]" />
                        {{ nav.title }}
                    </div>
                </div>
                <div v-if="currentTab" class="flex-1 relative g">
                    <div class="p-1">
                        <div class="shadow-md relative rounded-2xl bg-white flex gap-6 flex-col" v-show="currentTab === 'Pack Details'" >
                            <div class="p-3 panel-head bg-primary-300 font-semibold text-base-content flex gap-3 justify-between items-center rounded-t-2xl">
                                Credit Pack Details
                            </div>
                            <div class="py-24 flex items-center justify-center" v-if="isLoading">
                                <Loader />
                            </div>
                            <div class="p-3 overflow-y-scroll"  v-else>
                                <FormulateForm >
                                    <div class="grid grid-cols-3 gap-x-6">
                                        <FormulateInput 
                                            class="w-full disableInput" 
                                            label="Pack Name" 
                                            placeholder="CP-10000" 
                                            disabled 
                                            type="text" 
                                            :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)"
                                            v-model="packsDetails.name" 
                                            aria-readonly="true" 
                                        />
                                        <div class="p-2">
                                            <h1 class="flex gap-2">Validity In Days</h1>
                                            <label class="input-group input-group-md">
                                                <span>Days</span>
                                                <input type="text" placeholder="365" class="input input-bordered input-md h-10 w-full" 
                                                    v-model="packsDetails.validity" disabled
                                                />
                                            </label>
                                        </div> 
                                        <div class="p-2">
                                            <h1 class="flex gap-2">Discount</h1>
                                            <label class="input-group input-group-md">
                                                <span>%</span>
                                                <input type="text" placeholder="10" class="input input-bordered input-md h-10 w-full" 
                                                    v-model="packsDetails.discount" disabled
                                                />
                                            </label>
                                        </div> 
                                    </div>
                                    <div class="grid grid-cols-3 gap-x-6">
                                        <FormulateInput class="w-full disableInput" label="Credits" placeholder="CP-10000" type="text" 
                                            :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)"
                                            v-model="packsDetails.pack_credits" disabled
                                        />
                                        <div class="p-2">
                                            <h1 class="flex gap-2">List Price</h1>
                                            <label class="input-group input-group-md">
                                                <span>{{ packsDetails.currency_symbol }}</span>
                                                <input type="text" placeholder="10000" class="input input-bordered input-md h-10 w-full" 
                                                    v-model="packsDetails.list_price" disabled
                                                />
                                            </label>
                                        </div> 
                                        <div class="p-2">
                                            <h1 class="flex gap-2">Discounted Price</h1>
                                            <label class="input-group input-group-md">
                                                <span>{{ packsDetails.currency_symbol }}</span>
                                                <input type="text" placeholder="9900" class="input input-bordered input-md h-10 w-full" 
                                                    v-model="packsDetails.discounted_price" disabled
                                                />
                                            </label>
                                        </div>
                                    </div>
                                </FormulateForm>
                                </div>
                            </div>
                            <div class="shadow-md relative rounded-2xl bg-white flex gap-6 flex-col pb-4 " v-show="currentTab === 'Assign Client'" >
                                <div class="p-5 panel-head bg-primary-300 font-semibold text-base-content flex gap-3 justify-between items-center rounded-t-2xl">
                                    Assign Client
                                </div>
                                <div class="py-24 flex items-center justify-center" v-if="isLoading">
                                    <Loader />
                                </div>
                                <div class="px-5" overflow-y-scroll  v-else>
                                    <div>
                                        <customTable :columns="cols" :rows="row" 
                                            selectAll 
                                            :handleSelectAll="handleSelect" 
                                            searchBar> 
                                            <template v-slot:name="props">
                                                <div class="flex items-center gap-2 p-1">
                                                    <input :key="props.item.id" type="checkbox" :checked="props.item.selected" v-model="props.item.selected" class="checkbox" 
                                                    @change="reArrange"
                                                    :disabled="!checkPermission('creditPack.allocate')"
                                                    />
                                                    <span>{{ props.item.name }}</span>
                                                </div>
                                            </template>
                                            <template v-slot:currency_code="props">
                                                <div class="flex items-center justify-center gap-2 p-1">
                                                    <input :disabled="true" type="text" class="input input-bordered w-20 h-7 "  v-model="props.item.currency_code"/>                                                    
                                                </div>
                                            </template>
                                            <template v-slot:list_price="props">
                                                <div class="flex items-center justify-end gap-2 p-1">
                                                    <label class="input-group input-group-md h-7 w-auto">
                                                        <span :aria-readonly="true">{{ packsDetails.currency_symbol }}</span>                                                    
                                                        <input type="text" class="input input-bordered  w-24 px-2 h-7"  v-model="props.item.list_price"
                                                        :disabled="disableCheckDefaultprice(props.item) || !checkPermission('creditPack.allocate')" 
                                                        />                                                    
                                                    </label>
                                                </div>
                                            </template>
                                            <template v-slot:discount="props">
                                                <div class="flex items-center justify-center gap-2 p-1">
                                                    <label class="input-group input-group-md h-7 w-auto">
                                                        <span>%</span>   
                                                        <input type="text" class="checkbox w-20 px-2 h-7"  v-model="props.item.discount" 
                                                        :disabled="!checkPermission('creditPack.allocate')"
                                                        @keypress="isNumber($event)"
                                                        @input="handleDiscountedPrice(props.item)"
                                                />                                                    
                                                    </label>
                                                </div>
                                                <span v-if="props.item.invalidDiscount" class="text-red-500 text-sm">Invalid Value</span>
                                            </template>
                                            <template v-slot:discounted_price="props">
                                                <div class="flex items-center justify-center gap-2 p-1">
                                                    <label class="input-group input-group-md h-7 w-auto">
                                                        <span>{{ packsDetails.currency_symbol }}</span>   
                                                        <input type="text" class="input input-bordered w-24 px-2 h-7"  
                                                            v-model="props.item.discounted_price" disabled />                                                    
                                                    </label>
                                                </div>
                                            </template>
                                            <template v-slot:default_list="props">
                                                <div class="flex items-center justify-center gap-2 p-1">
                                                    <input type="checkbox" :checked="props.item.default_price" 
                                                        class="checkbox" 
                                                        v-model="props.item.default_price" 
                                                        @change="defaultPrice(props.item)"
                                                        :disabled="!checkPermission('creditPack.allocate')"
                                                    />                                                   
                                                </div>
                                            </template>
                                        </customTable>
                                        <div class="flex justify-end max-w-full">
                                            <Button
                                                :disabled="!changeMade || !checkPermission('creditPack.allocate')"
                                                @click="assignPacks()"
                                                class="btn-primary float-right mt-4"
                                                text="Save"
                                                :loader="savingAssignPacks"
                                            />
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                <div v-else class="flex flex-1 bg-white p-3 h-full overflow-y-scroll scroll-bar justify-center items-center">No Options Selected</div>
            </div>
        </div>
    </div>
</template>
<script>
import SubHeader from "@/components/SubHeader";
import Button from "@/components/button";
import Loader from "@shared/loader";
import customTable from "@shared/components/custom-table";
import {getClientCreditPack, getAssignedCreditPack, getCurrencies, assignCreditPack, getclientId} from "../services"
import { checkDiscount, isNumber } from "@/plugins/functions"
import { checkPermission } from "@shared/utils/functions"

// End
export default {
        name: "pack-details",
        components: {
            SubHeader,
            Button,
            customTable,
            Loader,
        },
        data: () => ({
            changeMade: false,
            navigations: [
                {
                    title: "Pack Details",
                    nav: "Pack Details",
                    icon: "circle-check",
                    subnav: false,
                    iconPrefix: "far",
                },
                {
                    title: "Assign Client",
                    nav: "Assign Client",
                    icon: "file-lines",
                    subnav: false,
                    iconPrefix: "far",
                },
            ],
            currentTab: "Pack Details",
            customBreadCumbs: [],
            cols: [{
                    name: "User Name",
                    label: "name",
                    type: "custom"
                },{
                    name: "Currency",
                    label: "currency_code",
                    type: "custom"
                },{
                    name: "List Price",
                    label: "list_price",
                    type: "custom"
                },{
                    name: "Default List Price",
                    label: "default_list",
                    type: "custom"
                },{
                    name: "Discount",
                    label: "discount",
                    type: "custom"
                },{
                    name: "Discounted Price",
                    label: "discounted_price",
                    type: "custom"
                },
                { name: "selected", type: "extra", label: "selected" },],
                caseEventDetails: {},
                row: [], 
                packsDetails: {
                    name: null,
                    validity: null,
                    discount: null,
                    discounted_price: null,
                    pack_credits: null,
                    list_price: null,
                    decimal_place:null,
                    base_credit_pack_id: null,
                    tenant_base_credit_pack_id: null,
                    currency_symbol: null,
                },
                tenantId: null,
                isLoading: false,
                savingAssignPacks: false,
                hasDiscountError: false,
        }),
        async mounted() {
            this.isLoading = true
            await this.setCreditPacks()
            await this.setClientsData()
            // await this.setclientId()
            this.isLoading = false
            

        },
        watch:{
            row: {
                deep: true,
                handler() {
                    if (!this.isLoading) {
                        this.changeMade = true
                    }
                }
            }
        },
        methods:{   
            checkDiscount,
            checkPermission,
            isNumber,
            reArrange() {
                let selectedArr = [];
                let unSelectedArr = [];
                this.row.forEach((item) => {
                    if (item.selected) {
                        selectedArr.push(item)
                    }
                    else {
                        unSelectedArr.push(item)
                    }
                })
                this.row = [...selectedArr, ...unSelectedArr]
            },
            disableCheckDefaultprice(value) {
                return value.default_price
            },  
            selectedTab(tab) {
                if (this.currentTab === tab.nav) return;
                this.currentTab = tab.nav;
                this.setBredCrumbs(this.currentTab);
                this.$router.push({
                    name: this.currentTab,
                });
            },
            async setCreditPacks()
            {
                const {data} = await getClientCreditPack(this.$route.params.id)
                this.packsDetails = data
                const {decimal_place, list_price, discounted_price} = this.packsDetails
                this.packsDetails = {...this.packsDetails , list_price: list_price.toFixed(decimal_place), discounted_price:discounted_price.toFixed(decimal_place) }
                this.base_credit_pack_id = data.base_credit_pack_id
            },
            async setClientsData() {
                this.tenantId = this.$store.getters.getTenantId;
                let payload ={
                    tenant_id: this.tenantId,
                    count: 1000 // for now adding static count because otherwise api will only give 10 data (API should be modified.)
                }
               let clients =  await getclientId(payload)
                const currencyData = await getCurrencies()
                let clientsData = await getAssignedCreditPack(this.packsDetails.base_credit_pack_id)
                clientsData = clientsData.data.clients
                
                let mappedData  = clients.data.data.map((row)=>{
                    const {name,id,currency_id} = row;
                    const currency  = currencyData.data.data.find(res=> res.id == currency_id)
                    const tenantData = clientsData.find(res=>  res.client_id === id)
                return {
                    name,
                    id,
                    currency_code: currency?.currency_code ? currency.currency_code : '',
                    list_price: tenantData?.list_price ? tenantData.list_price.toFixed(currency.decimal_place) : this.packsDetails.list_price ? this.packsDetails.list_price: '',
                    discount: tenantData?.discount ? tenantData.discount: this.packsDetails.discount ? this.packsDetails.discount: 0,
                    validDiscountValue: tenantData?.discount ? tenantData.discount: this.packsDetails.discount ? this.packsDetails.discount: 0,
                    discounted_price: tenantData?.discounted_price ? tenantData.discounted_price.toFixed(currency.decimal_place) : '',
                    selected: tenantData ? true : false,
                    currency_id: currency?.id ? currency.id : '',
                    default_price: tenantData ? tenantData.default_price : true,
                    decimal_place: currency.decimal_place
                }

                })

                mappedData.forEach((res)=>{
                    const discount = (res.list_price * res.discount) / 100;
                    res.discounted_price = (res.list_price - discount).toFixed(res.decimal_place);
                })
                this.row = mappedData
                this.reArrange();
                // this.row.forEach((res) => this.handleDiscountedPrice(res));
            },
            async assignPacks() {
                try {
                    this.changeMade = false;
                    if (this.hasDiscountError) {
                        this.$toast.error('Invalid discount value!');
                        return;
                    }
                    this.savingAssignPacks = true
                    const selectedClients = this.row.filter(res=> res.selected == true)
                    let payload = {
                        base_credit_pack_id: this.packsDetails.base_credit_pack_id,
                        tenant_base_credit_pack_id: this.packsDetails.tenant_base_credit_pack_id,
                        clients:[]
                    }
                    selectedClients.forEach((res)=> {
                    payload.clients.push({
                        client_id: res.id,
                        currency_id: res.currency_id,
                        list_price: parseInt(res.list_price),
                        discount: parseInt(res.discount),
                        default_price: res.default_price,
                        discounted_price: parseInt(res.discounted_price)
                    })
                })
                await assignCreditPack(payload)
                this.$toast.success("Client's credit pack has been successfully updated")
                this.savingAssignPacks = false
                }
                catch (err) {
                    this.$toast.error("Something went wrong.")
                    this.savingAssignPacks = false
                }
            },
            handleDiscountedPrice(value) {
                if(value.discount > 100) {
                    value.discount = value.validDiscountValue
                }
                if(value.discount <= 100) {
                    value.validDiscountValue = value.discount
                    this.row?.forEach(rows => {
                        if (value.discount && !checkDiscount(value.discount, value.decimal_place ? value?.decimal_place : 2)) {
                            if (rows.id == value.id) {
                                rows.invalidDiscount = true;
                                this.hasDiscountError = true;
                            }
                        } else {
                            rows.invalidDiscount = false;
                            this.hasDiscountError = false;
                        }
                    })
                    const discount = (value.list_price * value.discount) / 100;
                    value.discounted_price = (value.list_price - discount).toFixed(value.decimal_place ? value.decimal_place : 2);
                }
            },
            defaultPrice(value) {
                    const savedPrice = this.packsDetails
                    this.row.forEach((res)=> {
                        if (res.id == value.id) {
                            res.list_price = savedPrice.list_price
                            res.discounted_price = (savedPrice.list_price - ((savedPrice.list_price * res.discount) / 100)).toFixed(value.decimal_place ? value.decimal_place : 2);
                        }
                    })
            },
            handleSelect(selectedAll) {
                if (selectedAll) {
                    this.row.forEach((res) => {
                        res.selected = true;
                    });
                } else {
                    this.row.forEach((res) => {
                        res.selected = false;
                    });
                }
            },
        },
    };
</script>
<style scoped lang="scss">
    ::v-deep {
    .disableInput {
        input {
            background: #f9fafb !important;
            cursor: not-allowed;
        }
    }
}
</style>